import React, { useState } from "react";
import Image from "next/image";
import Link from "next/link";
import { getDisplayName, multi, rgbDataURL } from "../../utils/tools";
import millify from "millify";
import { Heart } from "react-iconly";
import VoiceImage from "./../VoiceImage";
import { FaComment, FaHeart, FaPlay } from "react-icons/fa";
import ProfileBadge from "../ProfileBadge";
import { Play } from "lucide-react";
import { IoPlayOutline } from "react-icons/io5";
import dayjs from "dayjs";
import relativeTime from "dayjs/plugin/relativeTime";

const HomePageModelCard = ({ model, grid, animate, index }) => {
  dayjs.extend(relativeTime);
  const [isHovered, setIsHovered] = useState(false);

  const isCollection = model.isCollection;
  let rgb = model?.image?.blurhash.split(",");

  const defaultRGB = [255, 255, 255]; // default to white color

  const safeRGB = (rgb) => {
    if (!rgb || rgb.length !== 3) {
      return defaultRGB;
    }
    return rgb;
  };
  const badges = [...(model?.badges || []), ...(model?.classification || [])];

  return (
    <Link
      key={model._id}
      className={` tw-overflow-visible tw-h-auto   tw-w-80 tw-min-w-80 tw-max-w-80  tw-shadow-sm  tw-flex  md:tw-flex-row tw-bg-gray-100 hover:tw-bg-gray-200/70  tw-transition-all tw-transition-duration-700  tw-p-3 tw-rounded-3xl tw-items-center tw-relative ${
        grid ? "tw-w-full tw-max-w-full tw-min-w-full" : ""
      }`}
      href={`/${model?.urlParam}`}
      onMouseOver={() => {
        setIsHovered(true);
      }}
      onMouseLeave={() => {
        setIsHovered(false);
      }}
      style={{
        backgroundColor: isHovered
          ? `rgb(${safeRGB(rgb)[0]}, ${safeRGB(rgb)[1]}, ${safeRGB(rgb)[2]}, 0.35)`
          : `rgb(${safeRGB(rgb)[0]}, ${safeRGB(rgb)[1]}, ${safeRGB(rgb)[2]}, 0.25)`,
        animationDelay: `${index * 0.05}s`,
      }}
    >
      {/* {trending?.isTrending && (
        <div className="tw-absolute tw-top-0 tw-right-0 tw-text-white tw-font-black tw-text-sm tw-bg-black tw-rounded-full tw-p-1 tw-aspect-square">
          {trending?.position}
        </div>
      )} */}
      <VoiceImage
        model={model}
        className="tw-rounded-full tw-mr-2.5 "
        size={70}
      />
      <div className="tw-flex tw-flex-col tw-truncate tw-justify-between tw-h-full tw-w-full  ">
        <div>
          {model.lastUsed && (
            <div className="tw-text-xs tw-text-black/40 tw-font-semibold tw-mb-0.5">
              Last used {dayjs(model.lastUsed).fromNow()}
            </div>
          )}
          <div className="tw-font-bold tw-text-md tw-text-black tw-truncate">
            {model.artistname}
          </div>
          <div className="tw-text-xs tw-text-black/40 tw-font-semibold tw-truncate tw-mt-0.5">
            By @{model?.user?.username}
          </div>
          <div className="tw-flex tw-gap-2 tw-my-1 tw-mb-2">
            {model?.classification?.map((classification) => (
              <div
                className="tw-text-[10px] tw-text-black/40 tw-font-semibold tw-bg-black/5 tw-p-0.5 tw-rounded-full tw-px-1"
                key={classification}
              >
                #{classification}
              </div>
            ))}
          </div>

          {/* <div className=" tw-text-xs tw-text-wrap tw-line-clamp-2 tw-my-1 tw-text-black/50 tw-font-semibold">
            {model.description}
          </div> */}
        </div>

        <div className="tw-flex tw-gap-3">
          <div className=" tw-truncate tw-text-black/40 tw-font-bold">
            <div className="tw-text-xs tw-text-black/40 tw-font-light tw-truncate tw-flex tw-items-center tw-gap-1">
              <FaPlay strokeWidth={3} size={12} />{" "}
              {millify(Math.round(model.uses))}{" "}
            </div>
          </div>
          <div className="tw-truncate tw-text-black/40 tw-font-bold">
            <div className="tw-text-xs tw-text-black/40 tw-font-light tw-truncate tw-flex tw-items-center tw-gap-1">
              <FaHeart strokeWidth={3} size={12} />{" "}
              {millify(Math.round(model.likes ?? 0))}{" "}
            </div>
          </div>
          <div className="tw-truncate tw-text-black/40 tw-font-bold">
            <div className="tw-text-xs tw-text-black/40 tw-font-light tw-truncate tw-flex tw-items-center tw-gap-1">
              <FaComment strokeWidth={3} size={12} />{" "}
              {millify(Math.round(model.comments ?? 0))}{" "}
            </div>
          </div>
        </div>
        {/* <ProfileBadge
          username={model?.user?.username}
          avatar={model?.user?.avatar}
          className="tw-mt-1"
        /> */}
      </div>
    </Link>
  );
};

export default HomePageModelCard;

import { Spinner } from "@jammable/ui-components/Loader";
import { Download, Music, Repeat, X } from "lucide-react";
import Image from "next/image";
import React, { useState, useRef, useContext, createContext } from "react";
import { useEffect } from "react";
import { FaPlay, FaPause, FaMicrophone, FaDrum } from "react-icons/fa";
import ReactPlayer from "react-player";
import { formatDuration } from "../utils/tools";
import { HiArrowDownTray } from "react-icons/hi2";
import Dropdown from "./dropdown/Dropdown";
import { DropdownMenuItem } from "@jammable/ui-components/DropdownMenu";
import axios from "axios";
import { getAuth } from "firebase/auth";
import { BsMusicPlayerFill } from "react-icons/bs";

const AudioPlayerContext = createContext();

export const useAudioPlayer = () => {
  return useContext(AudioPlayerContext);
};

const GlobalAudioPlayer = ({ children }) => {
  const [isPlaying, setIsPlaying] = useState(false);
  const [audioSrc, setAudioSrc] = useState(null);
  const [imageSrc, setImageSrc] = useState(null);
  const [info, setInfo] = useState(null);
  const [currentTime, setCurrentTime] = useState(0);
  const playerRef = useRef(null);
  const [isLoading, setIsLoading] = useState(false);
  const [duration, setDuration] = useState(info?.duration ?? 0);

  const playAudio = ({ audio, info }) => {
    if (audioSrc !== audio) {
      setAudioSrc(audio);
      setInfo(info);
    }
    setIsPlaying(true);
  };

  const pauseAudio = () => {
    setIsPlaying(false);
  };

  const handleScrub = (event) => {
    const scrubTime =
      (event.nativeEvent.offsetX / event.target.clientWidth) * duration;
    playerRef.current.seekTo(scrubTime, "seconds");
    setCurrentTime(scrubTime);
  };
  useEffect(() => {
    setCurrentTime(0);
  }, [audioSrc]);

  const handleDownload = async (type) => {
    const auth = getAuth();
    const token = await auth.currentUser.getIdToken();
    const response = await axios.get(`/api/s3/downloadFileUrl`, {
      params: {
        conversionId: info.id,
        type,
      },

      headers: { Authorization: token },
    });
    window.location.href = response.data.url;
  };
  const downloadOptions = [
    {
      type: "combined",
      label: "Combined",
      url: info?.outputUrls?.audio,

      icon: <BsMusicPlayerFill />,
    },
    {
      type: "acapella",
      label: "Acapella",
      url: info?.convertedUrl,
      icon: <FaMicrophone />,
    },
    {
      type: "instrumental",
      label: "Instrumental",
      url: info?.instrumentalUrl,
      icon: <FaDrum />,
    },
  ];
  const [repeat, setRepeat] = useState(false);
  const handleDuration = (duration) => {
    setDuration(duration);
  };

  const [isVisible, setIsVisible] = useState(false);
  const [isHidden, setIsHidden] = useState(true);
  const [isMounted, setIsMounted] = useState(false);

  useEffect(() => {
    setIsMounted(true);
  }, []);

  useEffect(() => {
    if (audioSrc) {
      setIsHidden(false);
      setTimeout(() => {
        setIsVisible(true);
      }, 50);
    } else {
      setIsVisible(false);
      setTimeout(() => {
        setIsHidden(true);
      }, 300); // Adjust this value to match your transition duration
    }
  }, [audioSrc]);
  return (
    <AudioPlayerContext.Provider
      value={{ playAudio, pauseAudio, isPlaying, info }}
    >
      {children}
      {/* {!audioSrc ? null : ( */}
      <>
        {isMounted && (
          <div
            className={`tw-w-full tw-fixed tw-bottom-0 tw-left-0 tw-right-0 tw-z-50 tw-justify-center tw-flex tw-pb-2 tw-px-2 ${isHidden ? "tw-hidden" : ""}`}
          >
            <div
              className={`tw-bg-white tw-z-50 tw-overflow-visible tw-flex tw-relative tw-justify-center tw-max-w-4xl tw-w-full tw-shadow-2xl tw-rounded-3xl tw-p-3 tw-transition-all ${
                isVisible
                  ? "tw-opacity-100 tw-h-[84px] tw-scale-100"
                  : "tw-opacity-0 tw-h-0  tw-p-0"
              }`}
            >
              <div
                onClick={() => {
                  setIsPlaying(false);
                  setAudioSrc(null);
                  setInfo(null);
                }}
                className="tw-absolute tw-size-6 tw-flex tw-items-center tw-justify-center tw--top-1 tw--right-1 tw-text-white tw-p-1 tw-bg-purple-700/90 tw-rounded-full tw-cursor-pointer"
              >
                <X size={14} strokeWidth={4} />
              </div>
              <ReactPlayer
                ref={playerRef}
                onDuration={handleDuration}
                url={audioSrc}
                playing={isPlaying}
                onEnded={() => {
                  if (repeat) {
                    playerRef.current.seekTo(0, "seconds");
                  } else {
                    setIsPlaying(false);
                  }
                }}
                onProgress={({ playedSeconds }) =>
                  setCurrentTime(playedSeconds)
                }
                width="0"
                height="0"
                onReady={() => setIsLoading(false)}
                onBuffer={() => setIsLoading(true)}
                onStart={() => setIsLoading(false)}
              />
              {audioSrc && (
                <div className="tw-flex tw-items-center tw-gap-2 tw-w-full tw-truncate">
                  <div className="tw-relative tw-rounded-full tw-bg-purple-700 tw-flex tw-items-center tw-justify-center">
                    <Image
                      className="tw-rounded-2xl"
                      src={info?.image}
                      alt="audio"
                      width={60}
                      height={60}
                    />
                    <div
                      onClick={() => {
                        if (isPlaying) {
                          setIsPlaying(false);
                        } else {
                          setIsPlaying(true);
                        }
                      }}
                      className="tw-absolute tw-inset-0 tw-flex tw-items-center tw-justify-center tw-cursor-pointer tw-bg-black/40 hover:tw-bg-black/50 tw-transition-all  tw-text-white tw-rounded-2xl "
                    >
                      {isLoading ? (
                        <Spinner
                          className="tw-border-white tw-border-3"
                          size={6}
                        />
                      ) : isPlaying ? (
                        <FaPause />
                      ) : (
                        <FaPlay />
                      )}
                    </div>
                  </div>
                  <div className="tw-flex tw-flex-col tw-w-full tw-truncate">
                    <div className="tw-text-base tw-font-black tw-truncate">
                      {info.name}
                    </div>
                    <div className="tw-text-sm tw-truncate tw-font-bold  tw-text-black/40 tw-mb-1.5">
                      {info.artistNames}
                    </div>
                    <div
                      className="tw-w-full tw-z-10 tw-h-1.5 tw-bg-gray-200 tw-rounded-full tw-relative tw-cursor-pointer"
                      onClick={handleScrub}
                    >
                      <div
                        className="tw-h-full tw-bg-purple-700 tw-rounded-full"
                        style={{
                          width: `${(currentTime / duration) * 100 || 0}%`,
                        }}
                      />
                    </div>
                  </div>
                  <div className="tw-flex tw-items-center tw-gap-1">
                    <div
                      className={`tw-flex tw-size-10  tw-w-10 tw-min-w-10 tw-items-center tw-justify-center tw-transition-all  tw-rounded-2xl tw-text-purple-700 tw-cursor-pointer ${
                        repeat
                          ? "tw-bg-purple-700/20  hover:tw-bg-purple-700/30"
                          : "tw-bg-gray-200 hover:tw-bg-gray-300 tw-text-gray-600"
                      }`}
                      onClick={() => setRepeat(!repeat)}
                    >
                      <Repeat size={20} strokeWidth={3} />
                    </div>

                    {/* {!info.isFree && (
                      <div className="tw-flex tw-items-end tw-gap-2">
                        <Dropdown
                          className="tw-gap-0.5 tw-rounded-3xl"
                          dropdownItems={
                            <>
                              <div className="tw-text-xs  tw-text-center tw-mb-1 tw-p-1 tw-px-2 tw-pb-0 tw-font-black tw-text-black/40 tw-uppercase tw-tracking-widest">
                                Download FILES
                              </div>
                              {downloadOptions.map(
                                ({ type, label, url, icon }) =>
                                  url && (
                                    <DropdownMenuItem
                                      key={type}
                                      className={
                                        "tw-font-bold tw-gap-1 tw-justify-between tw-cursor-pointer hover:tw-bg-gray-100 tw-p-2 tw-px-3 tw-rounded-2xl"
                                      }
                                      onClick={() => handleDownload(type)}
                                    >
                                      {label}
                                      {icon}
                                    </DropdownMenuItem>
                                  ),
                              )}
                            </>
                          }
                        >
                          <div className="tw-flex tw-size-10  tw-w-10 tw-min-w-10 tw-items-center tw-justify-center tw-p-2 tw-size-10 hover:tw-bg-purple-700/30 tw-transition-all  tw-bg-purple-700/20 tw-rounded-2xl tw-text-purple-700 tw-cursor-pointer">
                            <Download size={20} strokeWidth={3} />
                          </div>
                        </Dropdown>
                      </div>
                    )} */}
                  </div>
                </div>
              )}
            </div>
          </div>
        )}
      </>
      {/* //   )} */}
    </AudioPlayerContext.Provider>
  );
};

export default GlobalAudioPlayer;

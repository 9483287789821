import { ObjectId } from "mongodb";
import mongoose, { Schema, Document, Model } from "mongoose";

export interface ConversionDocument extends Document {
  userid: string;
  modelIds: ObjectId[];
  jobid?: string;
  originalUrl: string;
  convertedUrl: string; // vocals
  instrumentalUrl?: string;
  status?: string;
  conversionType?: string;
  public: boolean;
  shortid: string;
  name?: string;
  downloads: number;
  coverType?: string;
  showcase: boolean;
  youtubeUrl?: string;
  ip?: string;
  mode?: string;
  failCount: number;
  toolMode: {
    isToolMode: boolean;
    type?: string;
  };
  deleted: boolean;
  type: string;
  isFree: boolean;
  schemaVersion: number;
  createdAt: Date;
  updatedAt: Date;
  outputUrls?: {
    audio?: string;
    video?: string;
  };
  voiceTimes: [];
  userPlanName?: string;
  duration: number;
  clip?: {
    inTime?: number;
    outTime?: number;
    duration?: number;
  };
  outputFileIds?: ObjectId[];
  inputFileIds?: ObjectId[];
  coverSettings?: {
    addEffects?: boolean;
    extractBackground?: boolean;
    pitch?: any[];
    highQuality?: boolean;
    levelDb?: number;
    skipExtraction?: boolean;
    swapRate?: number;
    autotune?: {
      enabled?: boolean;
      strength?: number;
    };
  };
  metadata?: any;
}

const ConversionSchema = new Schema<ConversionDocument>(
  {
    userid: {
      type: String,
      required: true,
    },
    modelIds: [{ type: mongoose.Schema.Types.ObjectId, ref: "Model" }],
    voiceTimes: [],
    jobid: {
      type: String,
    },
    originalUrl: {
      type: String,
      required: true,
    },
    convertedUrl: {
      type: String,
    },

    instrumentalUrl: {
      type: String,
    },
    status: {
      type: String,
    },
    outputFileIds: [{ type: mongoose.Schema.Types.ObjectId, ref: "File" }],
    inputFileIds: [{ type: mongoose.Schema.Types.ObjectId, ref: "File" }],
    conversionType: {
      type: String,
    },
    coverType: {
      type: String,
    },
    public: {
      type: Boolean,
      default: true,
    },
    outputUrls: {
      audio: {
        type: String,
      },
      video: {
        type: String,
      },
    },
    shortid: {
      type: String,
      unique: true,
    },
    name: {
      type: String,
    },
    downloads: {
      type: Number,
      default: 0,
    },
    showcase: {
      type: Boolean,
      default: false,
    },
    youtubeUrl: {
      type: String,
    },
    ip: {
      type: String,
    },
    type: {
      type: String,
    },
    mode: {
      type: String,
    },
    failCount: {
      type: Number,
      default: 0,
    },
    toolMode: {
      isToolMode: {
        type: Boolean,
        default: false,
      },
      type: {
        type: String,
      },
    },
    deleted: {
      type: Boolean,
      default: false,
    },
    isFree: {
      type: Boolean,
      default: false,
    },
    schemaVersion: {
      type: Number,
      default: 1,
    },
    userPlanName: {
      type: String,
      required: false,
    },
    duration: {
      type: Number,
      required: false,
    },
    clip: {
      duration: {
        type: Number,
      },
      inTime: {
        type: Number,
      },
      outTime: {
        type: Number,
      },
    },

    coverSettings: {
      addEffects: {
        type: Boolean,
      },
      extractBackground: {
        type: Boolean,
      },
      pitch: {
        type: Array,
      },
      highQuality: {
        type: Boolean,
      },
      levelDb: {
        type: Number,
      },
      skipExtraction: {
        type: Boolean,
      },
      swapRate: {
        type: Number,
      },
      autotune: {
        enabled: {
          type: Boolean,
        },
        strength: {
          type: Number,
        },
      },
    },
    metadata: {
      type: Object,
    },
  },
  { timestamps: true },
);

ConversionSchema.index({ createdAt: 1 });
ConversionSchema.index({ modelid: 1 });

const ConversionModel: Model<ConversionDocument> =
  mongoose?.models?.Conversion ||
  mongoose.model<ConversionDocument>("Conversion", ConversionSchema);

export default ConversionModel;
